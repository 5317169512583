<template>
  <div class="Gen-AI">
    <AiChatBot
      :loading="isLoading"
      :prompt="prompt"
      :list="list"
      @send="sendMsg"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
    AiChatBot: () => import("@/components/sections/AiChatBot.vue"),
  },
  props: {
    type: {
      type: String,
      default() {
        return "trip";
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "generativeAI/getLoading",
      tripPrompt: "generativeAI/getSingleTripPrompt",
      tripList: "generativeAI/getSingleTripList",
      //
      detailPrompt: "generativeAI/getTripDetailPrompt",
      detailList: "generativeAI/getTripDetailList",
    }),
    prompt() {
      return this.type == "trip" ? this.tripPrompt : this.detailPrompt;
    },
    list() {
      return this.type == "trip" ? this.tripList : this.detailList;
    },

    vehicleID() {
      return this.$route.query.vehicle_id;
    },
    tripID() {
      return this.$route.query.trip_id;
    },
  },
  mounted() {
    if (this.type == "trip") {
      const prm = [
        {
          id: `Give me an overall summary of all the trips by the vehicle with id:{request.GET.get("vehicle_id")} on the date: {request.GET.get("date")}, return your response in 2 lines`,
          name: "Summary of all the Trips",
        },
      ];
      this.$store.dispatch("generativeAI/singleTripPrompt", prm);
    } else {
      const prompt = [
        {
          id: `Give me an overall summary of all the trips by the vehicle with id:{request.GET.get("vehicle_id")} on the date: {request.GET.get("date")}, return your response in 2 lines`,
          name: "Summarize detail of the Trip",
        },
      ];
      this.$store.dispatch("generativeAI/tripDetailPrompt", prompt);
    }
  },
  methods: {
    sendMsg(value) {
      if (this.type == "trip") {
        this.tripSendMsg(value);
      } else {
        this.detailSendMsg(value);
      }
    },
    tripSendMsg(value) {
      const params = {
        ...value,
        vehicle_id: this.vehicleID,
        date: this.options.date,
        product: this.options.product,
        fleetId: this.options.fleetId,
        oem_b2b_ids: this.options.oem_b2b_ids,
        tenant_id: this.options.tenant_id,
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        //date: this.getCurrentDate(),
      };
      this.$store.dispatch("generativeAI/singleTripList", params);
    },
    detailSendMsg(value) {
      const params = {
        ...value,
        trip_id: this.tripID,
        // date: this.options.date,
        date: this.getCurrentDate(),
      };
      this.$store.dispatch("generativeAI/tripDetailList", params);
    },
    getCurrentDate() {
      const dt = new Date();
      const y = dt.getFullYear();
      let m = dt.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;

      let d = dt.getDate();
      d = d < 10 ? `0${d}` : d;

      return `${y}-${m}-${d}`;
    },
  },
};
</script>
